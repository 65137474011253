header {
    margin-top: 140px;
    text-align: center;
}

.main-logo {
    font-weight: bold;
    font-size: 80px;

    @media (width <= 700px) {
        font-size: 60px;
    }
}

.main-logo-tools {
    font-weight: bold;
    font-size: 65px;

    @media (width <= 700px) {
        font-size: 45px;
    }
}

.warning,
.message {
    margin: 20px 10px 0;
    color: var(--neutral-color-100);
    font-weight: 500;
    font-size: 22px;

    @media (width <= 700px) {
        font-size: 18px;
    }
}

.tools-table {
    margin: 45px auto 30px;
    border-collapse: collapse;
    width: 70%;
    color: var(--neutral-color-100);

    @media (width <= 700px) {
        width: 85%;
    }

    & tr {
        background-color: #2f343a;
    }

    & td {
        border: 4px solid #1b1e24;
        padding: 20px;
        font-weight: 500;
        font-size: 19px;
        text-align: left;

        @media (width <= 700px) {
            padding: 12px;
            font-size: 14px;
        }
    }

    & > tbody > tr > td:first-of-type {
        width: 15vw;
    }
}

.page-icon {
    margin-right: 5px;
}
